<template>
    <div class="payment">
        <div v-if="!showSuccessMessage && !showErrorMessage">

        </div>
        <div v-if="showSuccessMessage" class="booking-completed-container">
          <div class="booking-completed-header">Tak for din booking</div>
          <div class="booking-completed-booking-number">Booking nr. #{{ onpayResponse.onpay_reference }}</div>
          <div class="booking-completed-message">
            Vi har nu modtaget og behandlet din booking. Det næste skridt, er at
            tilføje dit medie til skærmen. <p>Dette kan du gøre under Min konto ->
            Mine bookings.</p>
          </div>
          <br />
          <div class="center-content">
            <el-button  @click="downloadPDF(onpayResponse.onpay_reference);" class="add-media-btn btn-center" size="medium">Download kviterring</el-button>
          </div>
          <br />
          <div>
            Har du spørgsmål til din booking er du velkommen til at kontakte os på
            kontakt@reklameskaerm.dk. <p>Husk blot at angive dit booking nr. så vi
            hurtigere kan hjælpe.</p>
          </div>
      </div>
        <div v-if="showErrorMessage">

        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import { ElLoading } from 'element-plus';

export default {
    name: 'Payment',
    data() {
        return {
            onpayResponse: Object,
            showSuccessMessage: false,
            showErrorMessage: false
        }
    },
    mounted () {
        var loader = this.createLoader(true, true, "Gennemgår betaling vent venligst...");

        var search = location.search.substring(1);

        if(search.includes('payWithInvoice'))
        {
          const invoiceID = location.search.replace('?payWithInvoice&', '');
          axios.post(`${process.env.VUE_APP_BACKEND_URL}Invoice/ValidatePayment`, {
              "InvoiceID": invoiceID,
              "SHA1": "hmca_SHA1"
          })
          .then(response => {
              response = response.data;
              if(response.data === true) {
                  this.showSuccessMessage = true;
                  this.onpayResponse.onpay_reference = invoiceID 
                  
              }
              loader.close();
          }).catch(() => {
              console.log("Validation failed");
              loader.close();
          })
        }
        else {
          this.onpayResponse = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

          axios.post(`${process.env.VUE_APP_BACKEND_URL}Invoice/ValidatePayment`, {
              "InvoiceID": this.onpayResponse.onpay_reference,
              "SHA1": this.onpayResponse.onpay_hmac_sha1
          })
          .then(response => {
              response = response.data;
              if(response.data === true) {
                  this.showSuccessMessage = true;
                  window.history.pushState({}, document.title, "/" + "#/");
              }
              loader.close();
          }).catch(() => {
              console.log("Validation failed");
              loader.close();
          })
        }

    },
    methods: {
        createLoader(fullScreen, lockedScreen, text) {
          return ElLoading.service({ fullscreen: fullScreen, lock: lockedScreen, text: text})
      },
      // eslint-disable-next-line no-unused-vars
      downloadPDF(invoiceID) {
        axios({
          url: `${process.env.VUE_APP_BACKEND_URL}Invoice/DownloadInvoice?invoiceID=${invoiceID}`,
          method: 'GET',
          responseType: 'blob'
          }).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
        
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Faktura ordre nr ${invoiceID}.pdf`);
          document.body.appendChild(fileLink);
        
          fileLink.click();

          for(var index = 0; index < this.invoices.length; index++)
          {
            this.invoices[index].loading = false;
          }
        });
      },
    }
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .payment {
        margin-top: 50px !important;
    }
}

.booking-completed-container {
  width: 50%;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.booking-completed-container div {
  text-align: center;
}

.booking-completed-header {
  color: #9433ff;
  font-weight: 600;
  font-size: 26px;
}
</style>
